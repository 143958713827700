<template>
  <section id="users">
    <div class="row">
      <fieldset class="form-group">
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 :class="{
                    'is-valid': username.isValid,
                    'is-invalid': username.isValid === false,
                    'border-primary': username.isValid === undefined
                 }"
                 placeholder="Enter user name"
                 v-model="username.value"
                 @change="validateUsername(username)"
          />
          <input type="text"
                 class="form-control"
                 :class="{
                    'is-valid': password.isValid,
                    'is-invalid': password.isValid === false,
                    'border-primary': password.isValid === undefined
                 }"
                 placeholder="Enter password"
                 v-model="password.value"
                 @change="validatePassword(password)"
          />
          <select
              class="form-control"
              :class="{
                 'is-valid': role.isValid,
                 'is-invalid': role.isValid === false,
                 'border-primary': role.isValid === undefined
              }"
              v-model="role.value"
              @change="validateRole(role)"
          >
            <option value="" disabled hidden>Select user role</option>
            <option v-for="role in roles" :value="role.role_id">{{ role.name }}</option>
          </select>
          <button class="btn btn-primary" type="button" :disabled="!formValidated || adding" @click="addUser()">Add User</button>
        </div>
      </fieldset>
    </div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">User Name</th>
              <th scope="col">Role</th>
              <th scope="col">Removed</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="user" v-for="user in users">
              <th scope="row">{{ user.user_id }}</th>
              <td>{{ user.username }}</td>
              <td>{{ user.roles[0].name }}</td>
<!--              <td>{{ user.activated }}</td>-->
              <td>
                <button type="button"
                        class="btn btn-primary btn-sm"
                        v-if="!user.deleted"
                        @click="removeUser(user)">
                  Remove
                </button>
                {{ user.deleted }}
              </td>
              <td>
                <button type="button"
                        class="btn btn-primary btn-sm"
                        v-if="!user.deleted"
                        data-toggle="modal"
                        data-target="#edit-user-dialog"
                        @click="userBeingEdited = user">
                  Edit
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <EditUserDialog
        :user="userBeingEdited"
        :roles="roles"
        :validations="{
          username: validateUsername,
          password: validatePassword,
          role: validateRole
        }"
    ></EditUserDialog>
  </section>
</template>

<script>
import APIService from "@/services/APIService";
import EditUserDialog from "@/components/EditUserDialog";

export default {
  name: "Users",
  components: {EditUserDialog},
  data() {
    return {
      roles: [],
      users: [],
      filter: {},
      username: {
        value: null,
        error: null,
        isValid: undefined
      },
      password: {
        value: null,
        error: null,
        isValid: undefined
      },
      role: {
        value: null,
        error: null,
        isValid: undefined
      },
      error: null,
      adding: false,
      userBeingEdited: null
    }
  },
  computed: {
    formValidated() {
      return this.username.isValid && this.password.isValid && this.role.isValid;
    }
  },
  methods: {
    validateUsername: function(username) {
      username.isValid = !!username.value && !this.users.find(user => user.username === username.value);
    },
    validatePassword: function(password) {
      password.isValid = !!password.value;
    },
    validateRole: function(role) {
      role.isValid = this.roles.some(exists => exists.role_id === role.value);
    },
    addUser: async function() {
      this.error = null;
      this.adding = true;
      try {
        await APIService.addUser(this.username.value, this.password.value, [this.role.value]);
      } catch(e) {
        this.error = e.message;
      }
      this.username = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.password = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.role = {
        value: null,
        error: null,
        isValid: undefined
      };
      await this.refreshUsers();
      this.adding = false;
    },
    async removeUser(user) {
      this.error = null;
      try {
        await APIService.deleteUser(user.user_id);
      } catch(e) {
        this.error = e.message;
      }
      await this.refreshUsers();
    },
    async refreshUsers() {
      const users = await APIService.getUsers(this.filter);

      await Promise.all(
          users.map(async user => {
            user.roles = await APIService.getRolesForUser(user.user_id);
          })
      );

      this.users = users;
    }
  },
  async beforeCreate() {
    APIService.getRoles().then(roles => this.roles = roles);

    const users = await APIService.getUsers(this.filter);

    await Promise.all(
        users.map(async user => {
          user.roles = await APIService.getRolesForUser(user.user_id);
        })
    );

    this.users = users;
  },
  mounted() {
    $('#edit-user-dialog').on('hidden.bs.modal', this.refreshUsers);
  }
}
</script>

<style scoped>

</style>
