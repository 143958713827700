<template>
  <div class="modal fade" id="edit-user-dialog" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-if="user">
        <form>
          <div class="modal-header">
            <h4 class="modal-title">Edit User {{ user.user_id }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group">
                <label for="username">User name</label>
                <input
                    type="text" placeholder="Enter user name" id="username"
                    class="form-control"
                    :class="{
                      'is-valid': username.isValid,
                      'is-invalid': username.isValid === false,
                      'border-primary': username.isValid === undefined
                    }"
                    v-model="username.value"
                    @change="validateUsername(username)"
                />
              </div>
              <div class="form-group">
                <label for="password">Change password</label>
                <input
                    type="text" placeholder="Enter new password" id="password"
                    class="form-control"
                    :class="{
                      'is-valid': password.isValid,
                      'is-invalid': password.isValid === false,
                      'border-primary': password.isValid === undefined
                    }"
                    v-model="password.value"
                    @change="validatePassword(password)"
                />
              </div>
              <div class="form-group">
                <label for="role">User role</label>
                <select
                    id="role" name="role" class="form-control"
                    :class="{
                      'is-valid': role.isValid,
                      'is-invalid': role.isValid === false,
                      'border-primary': role.isValid === undefined
                    }"
                    v-model="role.value"
                    @change="validateRole(role)"
                >
                  <option v-for="role in roles" :value="role.role_id">{{ role.name }}</option>
                </select>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger">
              {{error}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" :disabled="!formValidated || saving" @click="editUser">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/APIService";

export default {
  name: "EditUserDialog",
  props: {
    user: {
      type: Object,
      required: false
    },
    roles: {
      type: Array,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    formValidated() {
      return this.username.isValid && this.role.isValid && (this.password.isValid || !this.password.value);
    }
  },
  watch: {
    user: function(user) {
      this.username.value = user.username;
      this.role.value = user.roles[0].role_id;
    }
  },
  data() {
    return {
      username: {
        value: null,
        error: null,
        isValid: true
      },
      password: {
        value: null,
        error: null,
        isValid: undefined
      },
      role: {
        value: null,
        error: null,
        isValid: true
      },
      error: null,
      saving: false,
    }
  },
  methods: {
    validateUsername: function(username) {
      this.validations.username(username);
      if(username.value === this.user.username) username.isValid = true;
    },
    validatePassword: function(password) {
      this.validations.password(password);
      if(!password.value) password.isValid = undefined;
    },
    validateRole: function(role) {
      this.validations.role(role);
    },
    close: function() {
      $('#edit-user-dialog').modal('hide');
    },
    editUser: async function() {
      if(!this.formValidated) return;

      this.error = null;
      this.saving = true;

      const fields = {
        username: this.username.value
      };
      if(this.password.value) {
        fields.password = this.password.value;
      }

      const roles = [this.role.value];

      try {
        await APIService.editUser(this.user.user_id, fields, roles);
        this.close();
      } catch(e) {
        this.error = e.message;
      }
      this.saving = false;
    }
  },
  mounted() {
    $('#edit-user-dialog').on('shown.bs.modal', () => {
      this.username.value = this.user.username;
      this.validateUsername(this.username);
      this.password.value = null;
      this.password.isValid = undefined;
      this.role.value = this.user.roles[0].role_id;
      this.validateRole(this.role);
    });
  }
}
</script>

<style scoped>

</style>
